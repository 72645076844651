<template>
  <div class="container">
    <h1 class="my-4">Past Uploads for Device</h1>
    <table class="table table-bordered">
      <thead>
        <tr>
          <th>Created On</th>
          <th>Image</th>
          <th>Converted Value</th>
          <th>Model Output</th>
          <th>Status</th>
          <th>Started On</th>
          <th>Finished On</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="upload in uploads" :key="upload.ID">
          <td>{{ upload.CreatedOn }}</td>
          <td v-if="upload.FilePath">
            <div class="image-container">
              <img
                :src="getImageUrl(upload.FilePath)"
                alt="Uploaded Image"
                style="max-height: 100px"
              />
            </div>
          </td>
          <td v-else></td>
          <td>{{ upload.Number01 }}</td>
          <td>{{ upload.Text01 }}</td>
          <td>{{ getStatusText(upload.Status) }}</td>
          <td>{{ upload.StartedOn }}</td>
          <td>{{ upload.FinishedOn }}</td>
        </tr>
      </tbody>
    </table>
    <!-- Pagination -->
    <nav aria-label="Pagination">
      <ul class="pagination justify-content-center">
        <!-- Previous Page Button -->
        <li class="page-item" :class="{ disabled: currentPage === 1 }">
          <button
            class="page-link"
            @click="prevPage"
            :disabled="currentPage === 1"
          >
            Previous
          </button>
        </li>
        <!-- Page Numbers -->
        <li
          class="page-item"
          v-for="page in totalPages"
          :key="page"
          :class="{ active: currentPage === page }"
        >
          <button class="page-link" @click="gotoPage(page)">{{ page }}</button>
        </li>
        <!-- Next Page Button -->
        <li class="page-item" :class="{ disabled: currentPage === totalPages }">
          <button
            class="page-link"
            @click="nextPage"
            :disabled="currentPage === totalPages"
          >
            Next
          </button>
        </li>
      </ul>
    </nav>

    <!-- Back to Device List Button -->
    <button class="btn btn-secondary" @click="backToDeviceList">
      Back to Device List
    </button>
  </div>
</template>

<script>
import axios from "axios";
import configService from "@/services/configService";

export default {
  name: "UploadList",
  //props: ['deviceId'],
  data() {
    return {
      uploads: [],
      currentPage: 1, // Current page number
      totalPages: 0, // Total number of pages
      deviceId: null, // Device ID received from route parameters
      apiBaseUrl: "",
    };
  },
  async created() {
    try {
      const config = await configService.loadConfig();
      this.apiBaseUrl = config.apiBaseUrl;
    } catch (error) {
      console.error("Failed to load configuration:", error);
    }
    this.fetchUploads();
  },
  methods: {
    fetchUploads() {
      const token = localStorage.getItem("token");
      this.deviceId = this.$route.params.deviceId;
      axios
        .get(
          `${this.apiBaseUrl}/devicefileuploads?page=${this.currentPage}&per_page=10&DeviceID=${this.deviceId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          this.uploads = response.data.uploads;
          this.totalPages = response.data.pages;
        })
        .catch((error) => {
          console.error("Error fetching uploads:", error);
          if (error.response && error.response.status === 401) {
            this.$router.push({ name: "Login" });
          }
        });
    },
    // Method to navigate to the previous page
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
        this.fetchUploads();
      }
    },
    // Method to navigate to the next page
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
        this.fetchUploads();
      }
    },
    // Method to navigate to a specific page
    gotoPage(page) {
      this.currentPage = page;
      this.fetchUploads();
    },
    // Method to navigate back to the device list
    backToDeviceList() {
      this.$router.push({ name: "DeviceList" });
    },
    getImageUrl(filePath) {
      //const filename = filePath.split('/').pop();
      return `${this.apiBaseUrl}/${filePath}`;
    },
    getStatusText(status) {
      if (status === 0) return "New";
      if (status === 5) return "Processed";
      if (status === 4) return "Error";
      return "Unknown";
    },
  },
};
</script>

<style scoped>
.image-container {
  height: 100px; /* Set height of image container */
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
}
</style>
