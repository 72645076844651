<!-- src/components/EditDevice.vue -->
<template>
  <div class="container">
    <h1>Edit Device</h1>
    <form @submit.prevent="submitForm">
      <div class="form-group">
        <label for="deviceName">Device Name</label>
        <input
          type="text"
          class="form-control"
          id="deviceName"
          v-model="form.deviceName"
          required
        />
      </div>
      <div class="form-group">
        <label for="deviceDescription">Device Description</label>
        <input
          type="text"
          class="form-control"
          id="deviceDescription"
          v-model="form.deviceDescription"
        />
      </div>
      <div class="form-group">
        <label for="deviceSerialNum">Device Serial Number</label>
        <input
          type="text"
          class="form-control"
          id="deviceSerialNum"
          v-model="form.deviceSerialNum"
        />
      </div>
      <button type="submit" class="btn btn-primary">Update Device</button>
    </form>
  </div>
</template>

<script>
import axios from "axios";
import configService from "@/services/configService";

export default {
  name: "EditDevice",
  data() {
    return {
      form: {
        deviceName: "",
        deviceDescription: "",
        deviceType: 1,
        deviceSerialNum: "",
      },
      deviceCategories: [],
      apiBaseUrl: "",
    };
  },
  async created() {
    try {
      const config = await configService.loadConfig();
      this.apiBaseUrl = config.apiBaseUrl;
      this.fetchDeviceCategories();
      this.fetchDeviceDetails();
    } catch (error) {
      console.error("Failed to load configuration:", error);
    }
  },
  methods: {
    async fetchDeviceCategories() {
      try {
        const token = localStorage.getItem("token");
        const response = await axios.get(
          `${this.apiBaseUrl}/devicecategories`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        this.deviceCategories = response.data; 
      } catch (error) {
        console.error("Error fetching device categories:", error);
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: "Login" });
        }
      }
    },
    fetchDeviceDetails() {
      const token = localStorage.getItem("token");
      const deviceId = this.$route.params.deviceId;
      axios
        .get(`${this.apiBaseUrl}/devicedetail/${deviceId}`, {
          headers: {
            "ngrok-skip-browser-warning": "true",
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          this.form = response.data;
        })
        .catch((error) => {
          console.error("Error fetching device details:", error);
        });
    },
    submitForm() {
      const token = localStorage.getItem("token");
      const deviceId = this.$route.params.deviceId;
      axios
        .put(`${this.apiBaseUrl}/devices/${deviceId}`, this.form, {
          headers: {
            "ngrok-skip-browser-warning": "true",
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          console.log("Device updated successfully:", response.data);
          this.$router.push({ name: "DeviceList" });
        })
        .catch((error) => {
          console.error("Error updating device:", error);
          if (error.response && error.response.status === 401) {
            this.$router.push({ name: "Login" });
          }
        });
    },
  },
};
</script>

<style scoped>
.container {
  margin-top: 20px;
}
</style>
