<template>
  <div v-if="showCaptureImage">
    <h2>Capture Image</h2>
    <div v-if="showWebcam">
      <vue-web-cam
        ref="webcam"
        :device-id="deviceId"
        @capture="onPhotoCapture"
      ></vue-web-cam>
      <button @click="capturePhoto">Capture Photo</button>
      <button @click="closeWebcam">Close</button>
    </div>
    <div v-else>
      <button @click="openWebcam">Open Webcam</button>
    </div>
    <div v-if="photo">
      <h3>Captured Photo</h3>
      <img :src="photo" alt="Captured Image" style="max-width: 100px" />
      <button @click="uploadPhoto">Upload Photo</button>
    </div>
  </div>
</template>

<script>
import VueWebCam from "vue-web-cam";
import axios from "axios";
import configService from "@/services/configService";

export default {
  components: {
    VueWebCam,
  },
  props: {
    showCaptureImage: Boolean,
    deviceId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      showWebcam: false,
      photo: null,
      apiBaseUrl: "",
    };
  },
  async created() {
    try {
      const config = await configService.loadConfig();
      this.apiBaseUrl = config.apiBaseUrl;
    } catch (error) {
      console.error("Failed to load configuration:", error);
    }
  },
  methods: {
    openWebcam() {
      this.showWebcam = true;
    },
    closeWebcam() {
      this.showWebcam = false;
    },
    capturePhoto() {
      this.$refs.webcam.capture();
    },
    onPhotoCapture(photoData) {
      this.photo = photoData;
    },
    async uploadPhoto() {
      if (this.photo) {
        try {
          const token = localStorage.getItem("token");

          const formData = new FormData();
          const blob = this.dataURItoBlob(this.photo);
          formData.append("imagefile", blob, "captured_image.png");
          formData.append("deviceId", this.deviceId);

          const response = await axios.post(
            `${this.apiBaseUrl}/upload`,
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
                "ngrok-skip-browser-warning": "true",
                Authorization: `Bearer ${token}`,
              },
            }
          );

          console.log("Upload successful:", response.data);
        } catch (error) {
          console.error("Error uploading photo:", error);
          if (error.response && error.response.status === 401) {
            this.$router.push({ name: "Login" });
          }
        }
      }
    },
    dataURItoBlob(dataURI) {
      const byteString = atob(dataURI.split(",")[1]);
      const mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
      const ab = new ArrayBuffer(byteString.length);
      const ia = new Uint8Array(ab);
      for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }
      return new Blob([ab], { type: mimeString });
    },
  },
};
</script>

<style scoped>
button {
  margin: 10px;
}
</style>
