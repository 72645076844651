<template>
    <div class="container">
      <h1>Edit Customer</h1>
      <form @submit.prevent="submitForm">
        <!-- Add form fields here similar to the form in AddMerchant.vue -->
        <div class="form-group">
          <label for="merchantCode">Customer Code</label>
          <input type="text" class="form-control" id="merchantCode" v-model="form.merchantcode" readonly required />
        </div>
        <div class="form-group">
          <label for="merchantName">Customer Name</label>
          <input type="text" class="form-control" id="merchantName" v-model="form.merchantname" required />
        </div>
        <div class="form-group">
          <label for="companyregno">Company Registration No</label>
          <input type="text" class="form-control" id="companyregno" v-model="form.companyregno" />
        </div>
        <div class="form-group">
          <label for="address">Address</label>
          <input type="text" class="form-control" id="address" v-model="form.address" />
        </div>
        <div class="form-group">
          <label for="postcode">Postcode</label>
          <input type="text" class="form-control" id="postcode" v-model="form.postcode" />
        </div>
        <div class="form-group">
          <label for="city">city</label>
          <input type="text" class="form-control" id="city" v-model="form.city" />
        </div>
        <div class="form-group">
          <label for="city">countryname</label>
          <input type="text" class="form-control" id="countryname" v-model="form.countryname" />
        </div>
        <div class="form-group">
          <label for="city">Contact Name</label>
          <input type="text" class="form-control" id="contactperson" v-model="form.contactperson" />
        </div>
        <div class="form-group">
          <label for="city">Contact Phone</label>
          <input type="text" class="form-control" id="contactphone" v-model="form.contactphone" />
        </div>
        <div class="form-group">
          <label for="city">Contact Email</label>
          <input type="text" class="form-control" id="contactemail" v-model="form.contactemail" />
        </div>
        <button type="submit" class="btn btn-primary">Update Merchant</button>
      </form>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  import configService from '@/services/configService';
  
  export default {
    name: 'EditMerchant',
    data() {
      return {
        form: {
          merchantcode: '',
          merchantname: ''
          // Add other fields as necessary
        },
        apiBaseUrl: '',
        id: null
      };
    },
    async created() {
      try {
        const config = await configService.loadConfig();
        this.apiBaseUrl = config.apiBaseUrl;
        this.id = this.$route.params.id;
        this.fetchMerchant();
      } catch (error) {
        console.error('Failed to load configuration:', error);
      }
    },
    methods: {
      async fetchMerchant() {
        const token = localStorage.getItem('token');
        try {
          const response = await axios.get(`${this.apiBaseUrl}/merchants/${this.id}`, {
            headers: {
              Authorization: `Bearer ${token}`
            }
          });
          this.form = response.data;
        } catch (error) {
          console.error('Error fetching merchant:', error);
          if (error.response && error.response.status === 401) {
            this.$router.push({ name: 'Login' });
          }
        }
      },
      submitForm() {
        const token = localStorage.getItem('token');
        axios
          .put(`${this.apiBaseUrl}/merchants/${this.id}`, this.form, {
            headers: {
              Authorization: `Bearer ${token}`
            }
          })
          .then(response => {
            console.log('Merchant updated successfully:', response.data);
            this.$router.push({ name: 'MerchantList' });
          })
          .catch(error => {
            console.error('Error updating merchant:', error);
            if (error.response && error.response.status === 401) {
              this.$router.push({ name: 'Login' });
            }
          });
      }
    }
  };
  </script>
  
  <style scoped>
  .container {
    margin-top: 20px;
  }
  </style>
  