import { createRouter, createWebHistory } from 'vue-router';
import DeviceList from './components/DeviceList.vue';
import UploadList from './components/UploadList.vue';
import UploadListCheck from './components/UploadListCheck.vue';
import DeviceDetail from './components/DeviceDetail.vue';
import QRCodeScanner from './components/QRCodeScanner.vue';
import CreateDevice from './components/CreateDevice.vue';
import EditDevice from './components/EditDevice';
import ViewDevice from './components/ViewDevice.vue';
import LoginForm from './components/LoginForm.vue';
import UserList from './components/UserList.vue';
import CreateUser from './components/CreateUser.vue';
import EditUser from './components/EditUser.vue';
import MerchantList from '@/components/MerchantList.vue';
import AddMerchant from '@/components/AddMerchant.vue';
import EditMerchant from '@/components/EditMerchant.vue';
import configService from "@/services/configService";
import axios from 'axios';
import MeterTypeList from '@/components/metertype/MeterTypeList.vue';
import AddMeterType from '@/components/metertype/AddMeterType.vue';
import EditMeterType from '@/components/metertype/EditMeterType.vue';
import ViewMeterType from '@/components/metertype/ViewMeterType.vue';
const routes = [
  {
    path: '/login',
    name: 'Login',
    component: LoginForm,
  },
  {
    path: '/',
    name: 'DeviceList',
    component: DeviceList,
  },
  {
    path: '/upload-list/:deviceId',
    name: 'UploadListRoute',
    component: UploadList,
    props: true,  // Enable passing route params as props
  },
  {
    path: '/upload-list-check/:deviceId',
    name: 'UploadListCheckRoute',
    component: UploadListCheck,
    props: true,  // Enable passing route params as props
  },
  {
    path: '/device/:qrcode',
    name: 'DeviceDetail',
    component: DeviceDetail
  },
  {
    path: '/scan',
    name: 'QRCodeScanner',
    component: QRCodeScanner
  },
  {
    path: '/device/create',
    name: 'CreateDevice',
    component: CreateDevice
  },
  {
    path: '/devices/edit/:deviceId',
    name: 'EditDevice',
    component: EditDevice,
    props: true
  },
  {
    path: '/view/:deviceId',
    name: 'ViewDevice',
    component: ViewDevice,
    props: true
  },
  ///////////user list
  {
    path: '/userlist',
    name: 'UserList',
    component: UserList
  },
  {
    path: '/createuser',
    name: 'CreateUser',
    component: CreateUser
  },
  {
    path: '/edit-user/:id',
    name: 'EditUser',
    component: EditUser
  },
  {
    path: '/merchant-list',
    name: 'MerchantList',
    component: MerchantList
  },
  {
    path: '/add-merchant',
    name: 'AddMerchant',
    component: AddMerchant
  },
  {
    path: '/edit-merchant/:id',
    name: 'EditMerchant',
    component: EditMerchant
  },
  //////////////////
  {
    path: '/metertypelist',
    name: 'MeterTypeList',
    component: MeterTypeList
  },
  {
    path: '/addmetertype',
    name: 'AddMeterType',
    component: AddMeterType
  },
  {
    path: '/editmetertype/:id',
    name: 'EditMeterType',
    component: EditMeterType
  },
  {
    path: '/viewmetertype/:id',
    name: 'ViewMeterType',
    component: ViewMeterType
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  mode: 'history',
});
router.beforeEach(async (to, from, next) => {
  const publicPages = ['/login'];
  const authRequired = !publicPages.includes(to.path);
  //const loggedIn = localStorage.getItem('token');
  const token = localStorage.getItem('token')
  /*
  if (authRequired && !loggedIn) {
    return next('/login');
  }
  next();
  */
  if (authRequired && token) {
    try {
      const config = await configService.loadConfig();
      const apiBaseUrl = config.apiBaseUrl;

      const response = await axios.get(`${apiBaseUrl}/check-token`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.data.msg === 'Token is valid') {
        return next();
      } else {
        localStorage.removeItem('token');
        return next('/login');
      }
    } catch (error) {
      // Token is invalid or request failed
      localStorage.removeItem('token');
      return next('/login');
    }
  } else if (authRequired && !token) {
    return next('/login');
  } else {
    next();
  }
});

export default router;