<template>
  <div class="container">
    <h1>Edit Meter Type</h1>
    <form @submit.prevent="editMeterType">
      <div class="form-group">
        <label for="name">Name</label>
        <input
          type="text"
          id="name"
          v-model="name"
          required
          class="form-control"
        />
      </div>
      <div class="form-group">
        <label for="description">Description</label>
        <input
          type="text"
          id="description"
          v-model="description"
          required
          class="form-control"
        />
      </div>
      <button type="submit" class="btn btn-primary">Save Changes</button>
      <!-- Back to Device List Button -->
      <button
        class="btn btn-secondary btn-action-top"
        @click="backToMeterTypeList"
      >
        Back to Meter Type List
      </button>
    </form>
  </div>
</template>

<script>
import axios from "axios";
import configService from "@/services/configService";

export default {
  data() {
    return {
      name: "",
      description: "",
      status: 0,
      apiBaseUrl: "",
    };
  },
  async created() {
    try {
      const config = await configService.loadConfig();
      this.apiBaseUrl = config.apiBaseUrl;
      this.fetchMeterType();
    } catch (error) {
      console.error("Failed to load configuration:", error);
    }
  },
  methods: {
    async fetchMeterType() {
      try {
        const token = localStorage.getItem("token");
        const response = await axios.get(
          `${this.apiBaseUrl}/metertype/${this.$route.params.id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const meterType = response.data;
        this.name = meterType.name;
        this.description = meterType.description;
        this.status = meterType.status;
      } catch (error) {
        console.error("Error fetching meter type:", error);
      }
    },
    async editMeterType() {
      try {
        const token = localStorage.getItem("token");
        await axios.put(
          `${this.apiBaseUrl}/metertype/${this.$route.params.id}`,
          {
            name: this.name,
            description: this.description,
            status: this.status,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        this.$router.push("/metertypelist");
      } catch (error) {
        console.error("Error editing meter type:", error);
      }
    },
    backToMeterTypeList() {
      this.$router.push({ name: "MeterTypeList" });
    },
  },
};
</script>
<style scoped>
.table {
  margin-top: 20px;
}
.pagination {
  margin-top: 20px;
}

.container {
  margin-top: 20px;
}

.device-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 16px;
  margin-top: 20px;
}

.device-card {
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 16px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.card-body {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.card-title {
  margin-bottom: 8px;
}

.card-text {
  margin-bottom: 16px;
}

.btn-action {
  margin: 5px 0;
  width: 100%;
}
.btn-action-grid {
  margin: 10px; /* Add margin around each button */
  width: 140px; /* Adjust the width as needed */
}
.btn-action-top {
  margin: 10px;
  width: 200px;
}

@media (min-width: 768px) {
  .device-card {
    flex-direction: row;
    justify-content: space-between;
  }
}
</style>
