<template>
  <div class="modal" tabindex="-1" role="dialog" v-if="isVisible">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Take Picture</h5>
          <button type="button" class="close" @click="close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <form @submit.prevent="submitForm">
            <div class="form-group" v-if="showFileInput">
              <label for="file">Choose file</label>
              <input
                type="file"
                class="form-control .file-upload-size"
                id="file"
                @change="onFileChange"
              />
            </div>
            <button type="submit" class="btn btn-primary btn-action-top" v-if="showFileInput">Upload</button>
            <button class="btn btn-primary  btn-action-top" @click="openCamera">
              Show Camera
            </button>
          </form>
          
          <div v-if="successMessage" class="alert alert-success mt-3">
            {{ successMessage }}
          </div>
          <!-- Add a video element to display the camera feed -->
          <div v-if="showCamera">
            <video ref="video" class="video-feed" autoplay></video>
            <button class="btn btn-primary mt-2" @click="captureImage">
              Capture
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import configService from "@/services/configService";
// import pica from 'pica'; // Uncomment this if using pica

export default {
  name: "FileUpload",
  props: {
    deviceId: {
      type: Number,
      required: true,
    },
    isVisible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      file: null,
      apiBaseUrl: "",
      successMessage: "",
      showCamera: false,
      showFileInput: true, // Add this property to manage file input visibility

    };
  },
  async created() {
    try {
      const config = await configService.loadConfig();
      this.apiBaseUrl = config.apiBaseUrl;
    } catch (error) {
      console.error("Failed to load configuration:", error);
    }
  },
  methods: {
    onFileChange(event) {
      this.file = event.target.files[0];
    },
    async submitForm() {
      if (!this.file) {
        return;
      }

      try {
        const resizedFile = await this.resizeImage(this.file);

        const formData = new FormData();
        formData.append("file", resizedFile);
        formData.append("deviceId", this.deviceId);
        const token = localStorage.getItem("token");
        await axios.post(`${this.apiBaseUrl}/upload`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            "ngrok-skip-browser-warning": "true",
            Authorization: `Bearer ${token}`,
          },
        });

        this.showSuccessMessage("File uploaded successfully");
      } catch (error) {
        console.error("Error uploading file:", error);
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: "Login" });
        }
      }
    },
    async resizeImage(file) {
      const MAX_WIDTH = 800;
      const MAX_HEIGHT = 800;

      return new Promise((resolve, reject) => {
        const img = new Image();
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");

        img.onload = () => {
          let width = img.width;
          let height = img.height;

          if (width > height) {
            if (width > MAX_WIDTH) {
              height *= MAX_WIDTH / width;
              width = MAX_WIDTH;
            }
          } else {
            if (height > MAX_HEIGHT) {
              width *= MAX_HEIGHT / height;
              height = MAX_HEIGHT;
            }
          }

          canvas.width = width;
          canvas.height = height;

          ctx.drawImage(img, 0, 0, width, height);

          canvas.toBlob(
            (blob) => {
              resolve(
                new File([blob], file.name, {
                  type: file.type,
                  lastModified: Date.now(),
                })
              );
            },
            file.type,
            0.95
          );
        };

        img.onerror = reject;
        img.src = URL.createObjectURL(file);
      });
    },
    showSuccessMessage(message) {
      this.successMessage = message;
      setTimeout(() => {
        this.successMessage = "";
        this.close(); // Close the modal after the message is cleared
      }, 3000); // Hide the message after 3 seconds
    },
    close() {
      this.$emit("close");
    },
    openCamera() {
      this.showCamera = true;
      this.showFileInput = false;
      navigator.mediaDevices
        .getUserMedia({
          video: { facingMode: { exact: "environment" } },
        })
        .then((stream) => {
          this.$refs.video.srcObject = stream;
        })
        .catch((error) => {
          console.error("Error accessing camera:", error);
        });
    },
    captureImage() {
      const video = this.$refs.video;
      const canvas = document.createElement("canvas");
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      const ctx = canvas.getContext("2d");
      ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
      canvas.toBlob(async  (blob) => {
        const file = new File([blob], "captured-image.jpg", { type: "image/jpeg" });
        const resizedFile = await this.resizeImage(file);
        this.showCamera = false;
        const formData = new FormData();
            formData.append("file", resizedFile);
            formData.append("deviceId", this.deviceId);
            const token = localStorage.getItem("token");
            await axios.post(`${this.apiBaseUrl}/upload`, formData, {
              headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${token}`,
              },
            });
            this.showSuccessMessage("Image captured and uploaded successfully");

        // Stop the video stream
        const stream = video.srcObject;
        const tracks = stream.getTracks();
        tracks.forEach((track) => track.stop());
      }, "image/jpeg", 0.95);
    },
  },
};
</script>

<style scoped>
.modal {
  display: block;
  background-color: rgba(0, 0, 0, 0.5);
}
.modal-dialog {
  max-width: 500px;
  margin: 30px auto;
}
.form-control {
  height: auto !important;
}
.alert-success {
  margin-top: 1rem;
}
.video-feed {
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 5px;
}
.btn-action {
  margin: 5px 0;
  width: 100%;
}
.btn-action-grid {
  margin: 10px; /* Add margin around each button */
  width: 140px; /* Adjust the width as needed */
}
.btn-action-top {
  margin: 10px;
  width: 200px;
}

</style>
