<template>
  <div id="app">
    <nav class="navbar navbar-expand-lg navbar-light bg-light">
      <a class="navbar-brand" href="#">Riza App</a>
      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarNav">
        <ul class="navbar-nav">
          <li class="nav-item" v-if="isLoggedIn">
            <router-link to="/" class="nav-link">Home</router-link>
          </li>
          <li class="nav-item" v-if="isLoggedIn">
            <router-link to="/scan" class="nav-link">Scan QR</router-link>
          </li>
          <li class="nav-item" v-if="isLoggedIn && isAdmin">
            <router-link to="/userlist" class="nav-link">User List</router-link>
          </li>
          <li class="nav-item" v-if="isLoggedIn && isAdmin">
            <router-link to="/merchant-list" class="nav-link">Customer List</router-link>
          </li>
          <li class="nav-item" v-if="isLoggedIn && isAdmin">
            <router-link to="/metertypelist" class="nav-link">Meter Type List</router-link>
          </li>
          <li class="nav-item" v-if="isLoggedIn">
            <button class="btn btn-link nav-link" @click="logout" v-if="isLoggedIn">Log Out</button>
          </li>
        </ul>
      </div>
    </nav>
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'App',
  data() {
    return {
      isLoggedIn: !!localStorage.getItem('token'),
      isAdmin: localStorage.getItem('is_admin') === 'true'
    };
  },
  methods: {
    logout() {
      localStorage.removeItem('token');
      localStorage.removeItem('is_admin');
      this.isLoggedIn = false;
      this.isAdmin = false;
      this.$router.push({ name: 'Login' });
    }
  },
  watch: {
    '$route'() {
      this.isLoggedIn = !!localStorage.getItem('token');
      this.isAdmin = localStorage.getItem('is_admin') === 'true';
    }
  }
};
</script>

<style>
/* Global styles */
</style>
