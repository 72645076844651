<template>
  <div class="qr-code-scanner">
    <qrcode-stream
      :formats="['qr_code']"
      @detect="onDetect"
      @error="onError"
      @decode="onDecode"
      @init="onInit"
    ></qrcode-stream>
    <div v-if="decodedResult" class="result">
      <h3>Decoded Result:</h3>
      <p>{{ decodedResult }}</p>
    </div>
    <div v-else>no data</div>
  </div>
</template>

<script>
import { QrcodeStream } from "vue-qrcode-reader";

export default {
  components: {
    QrcodeStream,
  },
  data() {
    return {
      decodedResult: null,
    };
  },
  methods: {
    onDetect(detectedCodes) {
      console.log(detectedCodes);
      this.decodedResult = detectedCodes;
      console.log("qrcode:" + detectedCodes[0].rawValue);

      // Redirect to the device detail page with the QR code result
      this.$router.push({
        name: "DeviceDetail",
        params: { qrcode: detectedCodes[0].rawValue },
      });
    },
    onError(error) {
      if (error.name === "NotAllowedError") {
        // user denied camera access permission
      } else if (error.name === "NotFoundError") {
        // no suitable camera device installed
      } else if (error.name === "NotSupportedError") {
        // page is not served over HTTPS (or localhost)
      } else if (error.name === "NotReadableError") {
        // maybe camera is already in use
      } else if (error.name === "OverconstrainedError") {
        // did you request the front camera although there is none?
      } else if (error.name === "StreamApiNotSupportedError") {
        // browser seems to be lacking features
      }
    },
    onDecode(result) {
      this.decodedResult = result;
      // Redirect to the device detail page with the QR code result
      this.$router.push({ name: "DeviceDetail", params: { qrcode: result } });
    },
    onInit(promise) {
      promise
        .then(() => {
          console.log("Successfully initialized!");
        })
        .catch((error) => {
          console.error("Failed to initialize QR code scanner:", error);
        });
    },
  },
};
</script>

<style scoped>
.qr-code-scanner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.result {
  margin-top: 20px;
}
</style>
