<template>
  <div class="container">
    <h1 class="my-4">Meter Types</h1>
    <button
      class="btn btn-primary btn-action-top"
      @click="navigateToCreateMeterType"
    >
      Create New Meter Type
    </button>
    <input
      v-model="search"
      placeholder="Search by name"
      @input="fetchMeterTypes"
      class="form-control mb-3"
    />
    <table class="table table-bordered">
      <thead>
        <tr>
          <th>Name</th>
          <th>Description</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="meterType in meterTypes" :key="meterType.id">
          <td>{{ meterType.name }}</td>
          <td>{{ meterType.description }}</td>
          <td>
            <button
              class="btn btn-secondary btn-action-grid"
              @click="editMeterType(meterType.id)"
            >
              Edit
            </button>
            &nbsp;&nbsp;
            <button
              class="btn btn-secondary btn-action-grid"
              @click="viewMeterType(meterType.id)"
            >
              View
            </button>
          </td>
        </tr>
      </tbody>
      <!-- Pagination -->
      <nav aria-label="Page navigation">
        <ul class="pagination justify-content-center">
          <li class="page-item" :class="{ disabled: currentPage === 1 }">
            <button class="page-link" @click="prevPage">Previous</button>
          </li>
          <li
            class="page-item"
            v-for="page in totalPages"
            :key="page"
            :class="{ active: currentPage === page }"
          >
            <button class="page-link" @click="gotoPage(page)">
              {{ page }}
            </button>
          </li>
          <li
            class="page-item"
            :class="{ disabled: currentPage === totalPages }"
          >
            <button class="page-link" @click="nextPage">Next</button>
          </li>
        </ul>
      </nav>
    </table>
  </div>
</template>

<script>
import axios from "axios";
import configService from "@/services/configService";

export default {
  data() {
    return {
      meterTypes: [],
      search: "",
      page: 1,
      perPage: 10,
      totalPages: 0,
      apiBaseUrl: "",
    };
  },
  async created() {
    try {
      const config = await configService.loadConfig();
      this.apiBaseUrl = config.apiBaseUrl;
      this.fetchMeterTypes();
    } catch (error) {
      console.error("Failed to load configuration:", error);
    }
  },
  methods: {
    async fetchMeterTypes() {
      try {
        const token = localStorage.getItem("token");
        const response = await axios.get(`${this.apiBaseUrl}/metertypelist`, {
          params: {
            page: this.page,
            per_page: this.perPage,
            search: this.search,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        this.meterTypes = response.data.metertypes;
        this.totalPages = response.data.pages;
      } catch (error) {
        console.error("Error fetching meter types:", error);
      }
    },
    prevPage() {
      if (this.page > 1) {
        this.page--;
        this.fetchMeterTypes();
      }
    },
    nextPage() {
      if (this.page < this.totalPages) {
        this.page++;
        this.fetchMeterTypes();
      }
    },
    navigateToCreateMeterType() {
      this.$router.push({ name: "AddMeterType" });
    },
    editMeterType(id) {
      this.$router.push({ name: "EditMeterType", params: { id } });
    },
    viewMeterType(id) {
      this.$router.push({ name: "ViewMeterType", params: { id } });
    },
  },
};
</script>
<style scoped>
.container {
  margin-top: 20px;
}

.device-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 16px;
  margin-top: 20px;
}

.device-card {
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 16px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.card-body {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.card-title {
  margin-bottom: 8px;
}

.card-text {
  margin-bottom: 16px;
}

.btn-action {
  margin: 5px 0;
  width: 100%;
}
.btn-action-grid {
  margin: 10px; /* Add margin around each button */
  width: 140px; /* Adjust the width as needed */
}
.btn-action-top {
  margin: 10px;
  width: 200px;
}

@media (min-width: 768px) {
  .device-card {
    flex-direction: row;
    justify-content: space-between;
  }
}
</style>
