/*
import { createApp } from 'vue';
import App from './App.vue';


const app = createApp(App);
app.mount('#app');
*/

import { createApp } from 'vue';
import App from './App.vue';
import router from './router'; // Import the Vue Router configuration
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js'; 
createApp(App)
	.use(router)
	.mount('#app');
