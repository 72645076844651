<template>
  <div class="container">
    <h1>Create New User</h1>
    <div class="create-user">
      <form @submit.prevent="createUser">
        <div class="form-group">
          <label for="username">User Name</label>
          <input
            type="text"
            v-model="username"
            id="username"
            required
            class="form-control"
          />
        </div>
        <div class="form-group">
          <label for="useremail">Email</label>
          <input
            type="email"
            v-model="useremail"
            id="useremail"
            required
            class="form-control"
          />
        </div>
        <div class="form-group">
          <label for="password">Password</label>
          <input
            type="password"
            v-model="password"
            id="password"
            required
            class="form-control"
          />
        </div>
        <div class="form-group">
          <label for="userrole">User Role</label>
          <select
            v-model="userrole"
            id="userrole"
            required
            class="form-control"
          >
            <option v-for="role in userRoles" :key="role.id" :value="role.id">
              {{ role.rolename }}
            </option>
          </select>
        </div>
        <div class="form-group" v-if="showMerchantField">
          <label for="merchant">Merchant Name</label>
          <v-select
            :options="filteredMerchants"
            label="merchantname"
            v-model="selectedMerchant"
            @search="fetchMerchants"
          />
        </div>
        <button type="submit" class="btn btn-primary">Create User</button>
      </form>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import configService from "@/services/configService";

export default {
  name: "CreateUser",
  components: { vSelect },
  data() {
    return {
      username: "",
      useremail: "",
      password: "",
      userrole: null,
      userRoles: [],
      selectedMerchant: null,
      filteredMerchants: [],
      apiBaseUrl: "",
    };
  },
  async created() {
    try {
      const config = await configService.loadConfig();
      this.apiBaseUrl = config.apiBaseUrl;
    } catch (error) {
      console.error("Failed to load configuration:", error);
    }
    this.fetchUserRoles();
    this.fetchMerchants();
  },
  computed: {
    showMerchantField() {
      return this.userrole !== 1;
    },
  },
  methods: {
    async fetchUserRoles() {
      try {
        const token = localStorage.getItem("token");
        const response = await axios.get(`${this.apiBaseUrl}/userroles`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        this.userRoles = response.data;
      } catch (error) {
        console.error("Error fetching user roles:", error);
      }
    },
    async fetchMerchants(searchTerm) {
      //if (searchTerm.length < 3) return;
      try {
        const token = localStorage.getItem("token");
        const response = await axios.get(`${this.apiBaseUrl}/merchantlist`, {
          params: { merchantname: searchTerm },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        this.filteredMerchants = response.data;
      } catch (error) {
        console.error("Error fetching merchants:", error);
      }
    },
    async createUser() {
      try {
        const token = localStorage.getItem("token");
        var selectedMerchantId = 0;
        if(this.userrole !== 1)
        {
          this.selectedMerchant.id;
        }
        await axios.post(
          `${this.apiBaseUrl}/users`,
          {
            username: this.username,
            useremail: this.useremail,
            password: this.password,
            roleid: this.userrole,
            merchantid: selectedMerchantId,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        this.$router.push("/userlist"); // Redirect to the user list page
      } catch (error) {
        console.error("Error creating user:", error);
      }
    },
  },
  mounted() {
    this.fetchUserRoles();
  },
};
</script>
