<template>
  <div class="container">
    <h1>View Device</h1>
    <form>
      <div class="form-group">
        <label for="deviceName">Device Name</label>
        <input
          type="text"
          class="form-control"
          id="deviceName"
          v-model="form.deviceName"
          readonly
        />
      </div>
      <div class="form-group">
        <label for="deviceDescription">Device Description</label>
        <input
          type="text"
          class="form-control"
          id="deviceDescription"
          v-model="form.deviceDescription"
          readonly
        />
      </div>
      <div class="form-group">
        <label for="deviceSerialNum">Device Serial Number</label>
        <input
          type="text"
          class="form-control"
          id="deviceSerialNum"
          v-model="form.deviceSerialNum"
          readonly
        />
      </div>
      <div class="form-group qr-code-container">
        <label for="qrCode">QR Code</label>
        <div v-if="qrCodeUrl" class="qr-code">
          <img :src="qrCodeUrl" alt="QR Code" />
        </div>
      </div>
    </form>
  </div>
</template>

<script>
//npm install qrcode
import axios from "axios";
import configService from "@/services/configService";
import QRCode from "qrcode";

export default {
  name: "ViewDevice",
  data() {
    return {
      form: {
        deviceName: "",
        deviceDescription: "",
        deviceType: "",
        deviceSerialNum: "",
        qrCode: "",
      },
      qrCodeUrl: "",
      apiBaseUrl: "",
    };
  },
  async created() {
    try {
      const config = await configService.loadConfig();
      this.apiBaseUrl = config.apiBaseUrl;
      this.fetchDeviceDetails();
    } catch (error) {
      console.error("Failed to load configuration:", error);
    }
  },
  methods: {
    fetchDeviceDetails() {
      const token = localStorage.getItem("token");
      const deviceId = this.$route.params.deviceId;
      axios
        .get(`${this.apiBaseUrl}/devicedetail/${deviceId}`, {
          headers: {
            "ngrok-skip-browser-warning": "true",
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          this.form = response.data;
          this.generateQrCode(this.form.qrCode);
        })
        .catch((error) => {
          console.error("Error fetching uploads:", error);
          if (error.response && error.response.status === 401) {
            this.$router.push({ name: "Login" });
          }
        });
    },
    generateQrCode(qrCode) {
      QRCode.toDataURL(qrCode, (err, url) => {
        if (err) {
          console.error("Error generating QR Code:", err);
        } else {
          this.qrCodeUrl = url;
        }
      });
    },
  },
};
</script>

<style scoped>
.container {
  margin-top: 20px;
}

.qr-code-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px; /* Adjust height as needed */
}

.qr-code {
  max-width: 100%; /* Ensure the QR code doesn't exceed container width */
}
</style>
