class ConfigService {
    async loadConfig() {
      const response = await fetch('/appsettings.json');
      if (!response.ok) {
        throw new Error(`Failed to fetch configuration: ${response.statusText}`);
      }
      const config = await response.json();
      return config;
    }
  }
  
  const configService = new ConfigService();
  export default configService;
  