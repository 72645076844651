<template>
    <div class="container">
      <h1>Add Customer</h1>
      <form @submit.prevent="submitForm">
        <!-- Add form fields here similar to the form in the API definition -->
        <div class="form-group">
          <label for="merchantName">Customer Name</label>
          <input type="text" class="form-control" id="merchantName" v-model="form.merchantname" required />
        </div>
        <div class="form-group">
          <label for="companyregno">Company Registration No</label>
          <input type="text" class="form-control" id="companyregno" v-model="form.companyregno" />
        </div>
        <div class="form-group">
          <label for="address">Address</label>
          <input type="text" class="form-control" id="address" v-model="form.address" />
        </div>
        <div class="form-group">
          <label for="postcode">Postcode</label>
          <input type="text" class="form-control" id="postcode" v-model="form.postcode" />
        </div>
        <div class="form-group">
          <label for="city">city</label>
          <input type="text" class="form-control" id="city" v-model="form.city" />
        </div>
        <div class="form-group">
          <label for="city">countryname</label>
          <input type="text" class="form-control" id="countryname" v-model="form.countryname" />
        </div>
        <div class="form-group">
          <label for="city">Contact Name</label>
          <input type="text" class="form-control" id="contactperson" v-model="form.contactperson" />
        </div>
        <div class="form-group">
          <label for="city">Contact Phone</label>
          <input type="text" class="form-control" id="contactphone" v-model="form.contactphone" />
        </div>
        <div class="form-group">
          <label for="city">Contact Email</label>
          <input type="text" class="form-control" id="contactemail" v-model="form.contactemail" />
        </div>
       
        <button type="submit" class="btn btn-primary">Add Customer</button>
      </form>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  import configService from '@/services/configService';
  
  export default {
    name: 'AddMerchant',
    data() {
      return {
        form: {
          merchantname: '',
          companyregno: '',
          address : '',
          postcode:'',
          city:'',
          countryname:'',
          contactperson:'',
          contactphone:'',
          contactemail:'',
        },
        apiBaseUrl: ''
      };
    },
    async created() {
      try {
        const config = await configService.loadConfig();
        this.apiBaseUrl = config.apiBaseUrl;
      } catch (error) {
        console.error('Failed to load configuration:', error);
      }
    },
    methods: {
      submitForm() {
        const token = localStorage.getItem('token');
        axios
          .post(`${this.apiBaseUrl}/merchants`, this.form, {
            headers: {
              Authorization: `Bearer ${token}`
            }
          })
          .then(response => {
            console.log('Merchant added successfully:', response.data);
            this.$router.push({ name: 'MerchantList' });
          })
          .catch(error => {
            console.error('Error adding merchant:', error);
            if (error.response && error.response.status === 401) {
              this.$router.push({ name: 'Login' });
            }
          });
      }
    }
  };
  </script>
  
  <style scoped>
  .container {
    margin-top: 20px;
  }
  </style>
  