<template>
  <div class="container">
    <h2>Edit User</h2>
    <form @submit.prevent="updateUser">
      <div class="form-group">
        <label for="username">Username</label>
        <input
          type="text"
          v-model="username"
          class="form-control"
          id="username"
          required
        />
      </div>
      <div class="form-group">
        <label for="useremail">Email</label>
        <input
          type="email"
          v-model="useremail"
          class="form-control"
          id="useremail"
          required
        />
      </div>
      <div class="form-group">
        <label for="userrole">Role</label>
        <select v-model="userrole" class="form-control" id="userrole" required>
          <option v-for="role in roles" :value="role.id" :key="role.id">
            {{ role.rolename }}
          </option>
        </select>
      </div>
      <div class="form-group" v-if="showMerchantField">
        <label for="merchant">Merchant</label>
        <select
          v-model="merchantid"
          class="form-control"
          id="merchant"
          required
        >
          <option
            v-for="merchant in merchants"
            :value="merchant.id"
            :key="merchant.id"
          >
            {{ merchant.merchantname }}
          </option>
        </select>
      </div>
      <button type="submit" class="btn btn-primary">Update User</button>
    </form>
  </div>
</template>

<script>
import axios from "axios";
import configService from "@/services/configService";

export default {
  name: "EditUser",
  data() {
    return {
      username: "",
      useremail: "",
      userrole: "",
      merchantid: "", // Ensure this is used correctly
      roles: [],
      merchants: [],
      apiBaseUrl: "",
    };
  },
  async created() {
    try {
      const config = await configService.loadConfig();
      this.apiBaseUrl = config.apiBaseUrl;
    } catch (error) {
      console.error("Failed to load configuration:", error);
    }
    this.fetchUserDetails();
    this.fetchRoles();
    this.fetchMerchants();
  },
  computed: {
    showMerchantField() {
      return this.userrole !== 1;
    },
  },
  methods: {
    async fetchUserDetails() {
      try {
        const token = localStorage.getItem("token");
        const { id } = this.$route.params;
        const response = await axios.get(`${this.apiBaseUrl}/users/${id}`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        const user = response.data;
        this.username = user.username;
        this.useremail = user.useremail;
        this.userrole = user.roleid;
        this.merchantid = user.merchantid; // Ensure this is set
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: "Login" });
        }
      }
    },
    async fetchRoles() {
      try {
        const token = localStorage.getItem("token");
        const response = await axios.get(`${this.apiBaseUrl}/userroles`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        this.roles = response.data;
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: "Login" });
        }
      }
    },
    async fetchMerchants() {
      try {
        const token = localStorage.getItem("token");
        const response = await axios.get(`${this.apiBaseUrl}/merchantlist`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        this.merchants = response.data;
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: "Login" });
        }
      }
    },
    async updateUser() {
      try {
        const { id } = this.$route.params;
        const token = localStorage.getItem("token");

        await axios.put(
          `${this.apiBaseUrl}/users/${id}`,
          {
            username: this.username,
            useremail: this.useremail,
            roleid: this.userrole,
            merchantid: this.userrole !== 1 ? this.merchantid : null, // Correctly set merchant ID based on role
          },
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        this.$router.push("/userlist");
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: "Login" });
        }
      }
    },
  },
};
</script>

<style scoped>
/* Add your styles here */
</style>
