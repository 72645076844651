<template>
  <div class="container">
    <h1 class="my-4">User List</h1>
    <button
      class="btn btn-primary btn-action-top"
      @click="navigateToCreateUser"
    >
      Create New User
    </button>
    <input
      type="text"
      v-model="searchQuery"
      @input="fetchUsers"
      placeholder="Search by email"
      class="form-control mb-3"
    />
    <table class="table table-bordered">
      <thead>
        <tr>
          <th>Username</th>
          <th>Email</th>
          <th>Merchant Code</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="user in users" :key="user.id">
          <td>{{ user.username }}</td>
          <td>{{ user.useremail }}</td>
          <td>{{ user.merchantcode }}</td>
          <td>
            <button
              class="btn btn-primary btn-action-top"
              @click="editUser(user.id)"
            >
              Edit User
            </button>
            <button
              class="btn btn-warning btn-action-top"
              @click="openResetPasswordModal(user.id)"
            >
              Reset Password
            </button>
          </td>
        </tr>
      </tbody>
    </table>
    <reset-user-password
      v-if="showResetForm"
      @close="showResetForm = false"
      :user-id="selectedUserId"
      :is-visible="showResetForm"
    />
    <!-- Pagination -->
    <nav aria-label="Page navigation">
      <ul class="pagination justify-content-center">
        <li class="page-item" :class="{ disabled: currentPage === 1 }">
          <button class="page-link" @click="prevPage">Previous</button>
        </li>
        <li
          class="page-item"
          v-for="page in totalPages"
          :key="page"
          :class="{ active: currentPage === page }"
        >
          <button class="page-link" @click="gotoPage(page)">{{ page }}</button>
        </li>
        <li class="page-item" :class="{ disabled: currentPage === totalPages }">
          <button class="page-link" @click="nextPage">Next</button>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
import axios from "axios";
import configService from "@/services/configService";
import ResetUserPassword from "./ResetUserPassword.vue";

export default {
  name: "UserList",
  components: {
    ResetUserPassword,
  },
  data() {
    return {
      users: [],
      searchQuery: "",
      currentPage: 1,
      perPage: 10,
      totalPages: 1,
      apiBaseUrl: "",
      showResetForm: false,
    };
  },
  async created() {
    try {
      const config = await configService.loadConfig();
      this.apiBaseUrl = config.apiBaseUrl;
    } catch (error) {
      console.error("Failed to load configuration:", error);
    }
    this.fetchUsers();
  },
  methods: {
    fetchUsers() {
      const token = localStorage.getItem("token");
      axios
        .get(`${this.apiBaseUrl}/users`, {
          params: {
            email: this.searchQuery,
            page: this.currentPage,
            per_page: this.perPage,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          this.users = response.data.users;
          this.totalPages = response.data.pages;
        })
        .catch((error) => {
          console.error("Error fetching uploads:", error);
          if (error.response && error.response.status === 401) {
            this.$router.push({ name: "Login" });
          }
        });
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
        this.fetchUsers();
      }
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
        this.fetchUsers();
      }
    },
    gotoPage(page) {
      this.currentPage = page;
      this.fetchUsers();
    },
    openResetPasswordModal(userId) {
      this.selectedUserId = userId;
      this.showResetForm = true;
    },
    navigateToCreateUser() {
      this.$router.push({ name: "CreateUser" });
    },
    editUser(id) {
      console.error("Error fetching uploads:" + id);
      this.$router.push({ name: "EditUser", params: { id } });
    },
  },
};
</script>

<style scoped>
.table {
  margin-top: 20px;
}
.pagination {
  margin-top: 20px;
}

.container {
  margin-top: 20px;
}

.device-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 16px;
  margin-top: 20px;
}

.device-card {
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 16px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.card-body {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.card-title {
  margin-bottom: 8px;
}

.card-text {
  margin-bottom: 16px;
}

.btn-action {
  margin: 5px 0;
  width: 100%;
}
.btn-action-grid {
  margin: 10px; /* Add margin around each button */
  width: 140px; /* Adjust the width as needed */
}
.btn-action-top {
  margin: 10px;
  width: 200px;
}

@media (min-width: 768px) {
  .device-card {
    flex-direction: row;
    justify-content: space-between;
  }
}
</style>
