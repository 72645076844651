<template>
  <div class="container">
    <h1 class="my-4">Device Details</h1>
    <div v-if="device">
      <p>Name: {{ device.DeviceName }}</p>
      <p>Serial Number: {{ device.DeviceSerialNum }}</p>
      <p v-if="latestUpload">
        <strong>Text 01:</strong> {{ latestUpload.Text01 }}
      </p>
      <p v-if="latestUpload">
        <strong>Number 01:</strong> {{ latestUpload.Number01 }}
      </p>
      <p v-else>Loading...</p>
      <!-- Add more fields as necessary -->
      <div class="button-group">
        <button class="btn btn-primary btn-action" @click="openForm(device.ID)">
          Manual</button
        >&nbsp;&nbsp;
        <button
          class="btn btn-secondary btn-action"
          @click="showUploadList(device.ID)"
        >
          Past Uploads</button
        >&nbsp;&nbsp;
        <button
          class="btn btn-primary btn-action"
          @click="openUploadForm(device.ID)"
        >
          Take Picture
        </button>
      </div>
      <device-form
        v-if="showForm"
        @close="showForm = false"
        :device-id="selectedDeviceId"
      />
      <file-upload
        v-if="showUploadForm"
        @close="showUploadForm = false"
        :device-id="selectedDeviceId"
        :is-visible="showUploadForm"
      />
    </div>
    <div v-else>
      <p>Loading device details...</p>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import DeviceForm from "./DeviceForm.vue";
import FileUpload from "./FileUpload.vue";
import configService from "@/services/configService";

export default {
  name: "DeviceDetail",
  components: {
    DeviceForm,
    FileUpload,
  },
  data() {
    return {
      device: null,
      showForm: false,
      showFormList: false,
      showUploadForm: false,
      selectedDeviceId: null,
      apiBaseUrl: "",
      latestUpload: null,
    };
  },
  async created() {
    try {
      const config = await configService.loadConfig();
      this.apiBaseUrl = config.apiBaseUrl;
      this.fetchDeviceDetails();
    } catch (error) {
      console.error("Failed to load configuration:", error);
    }
    this.fetchDeviceDetails();
  },
  methods: {
    fetchDeviceDetails() {
      const token = localStorage.getItem("token");

      const qrcode = this.$route.params.qrcode;
      axios
        .get(`${this.apiBaseUrl}/device?QRCode=${qrcode}`, {
          headers: {
            "ngrok-skip-browser-warning": "true",
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          this.device = response.data;
          this.selectedDeviceId = this.device.ID;
          this.fetchLatestUpload(this.device.ID);
        })
        .catch((error) => {
          console.error("Error fetching device details:", error);
          if (error.response && error.response.status === 401) {
            this.$router.push({ name: "Login" });
          }
        });
    },
    openForm(deviceId) {
      this.selectedDeviceId = deviceId;
      this.showForm = true;
    },
    showUploadList(deviceId) {
      this.$router.push(`/upload-list/${deviceId}`);
    },
    openUploadForm(deviceId) {
      this.selectedDeviceId = deviceId;
      this.showUploadForm = true;
    },
    async fetchLatestUpload(deviceId) {
      try {
        const token = localStorage.getItem("token");

        const response = await axios.get(
          `${this.apiBaseUrl}/latest_devicefileupload?deviceid=${deviceId}`,
          {
            headers: {
              "ngrok-skip-browser-warning": "true",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        this.latestUpload = response.data;
      } catch (error) {
        console.error("Error fetching latest upload:", error);
      }
    },
  },
};
</script>
