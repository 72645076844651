<template>
  <div class="container">
    <h1 class="my-4">Devices</h1>
    <button class="btn btn-primary mb-2 btn-action-top" @click="openQRScanner">
      Scan QR Code
    </button>
    <button
      class="btn btn-primary mb-2 btn-action-top"
      @click="navigateToCreateDevice"
    >
      Create New Device
    </button>
    <QRCodeScanner
      v-if="showQRScanner"
      @decoded="onQRCodeScanned"
      @close="closeQRScanner"
    />

    <!-- Card View for Mobile -->
    <div v-if="isMobile" class="device-grid">
      <div class="device-card" v-for="device in devices" :key="device.ID">
        <div class="card-body">
          <h5 class="card-title">
            <a href="#" @click.prevent="viewDevice(device.ID)">
              {{ device.DeviceName }}
            </a>
          </h5>
          <p class="card-text">Serial Number: {{ device.DeviceSerialNum }}</p>
          <button
            class="btn btn-primary btn-action"
            @click="openForm(device.ID)"
          >
            Manual Input
          </button>
          <button
            class="btn btn-secondary btn-action"
            @click="showUploadList(device.ID)"
          >
            Past Uploads
          </button>
          <button
            class="btn btn-primary btn-action"
            @click="openUploadForm(device.ID)"
          >
            Take Picture
          </button>
          <button
            class="btn btn-secondary btn-action"
            @click="editDevice(device.ID)"
          >
            Edit
          </button>
        </div>
      </div>
    </div>

    <!-- Table View for Desktop -->
    <table v-else class="table table-bordered">
      <thead>
        <tr>
          <th>Device Name</th>
          <th>Device Serial Number</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="device in devices" :key="device.ID">
          <td>
            <a href="#" @click.prevent="viewDevice(device.ID)">
              {{ device.DeviceName }}
            </a>
          </td>
          <td>
            <a href="#" @click.prevent="viewDevice(device.ID)">
              {{ device.DeviceSerialNum }}
            </a>
          </td>
          <td>
            <button
              class="btn btn-primary btn-action-grid"
              @click="openForm(device.ID)"
            >
              Manual Input</button
            >&nbsp;&nbsp;
            <button
              class="btn btn-secondary btn-action-grid"
              @click="showUploadList(device.ID)"
            >
              Past Uploads</button
            >&nbsp;&nbsp;
            <button
              class="btn btn-primary btn-action-grid"
              @click="openUploadForm(device.ID)"
            >
              Take Picture
            </button>
            <button
              class="btn btn-secondary btn-action-grid"
              @click="editDevice(device.ID)"
            >
              Edit
            </button>
          </td>
        </tr>
      </tbody>
    </table>

    <device-form
      v-if="showForm"
      @close="showForm = false"
      :device-id="selectedDeviceId"
    />
    <file-upload
      v-if="showUploadForm"
      @close="showUploadForm = false"
      :device-id="selectedDeviceId"
      :is-visible="showUploadForm"
    />
    <CaptureImage
      v-if="showCaptureImage"
      :showCaptureImage="showCaptureImage"
      :device-id="selectedDeviceId"
    />

    <!-- Pagination -->
    <nav aria-label="Page navigation">
      <ul class="pagination justify-content-center">
        <li class="page-item" :class="{ disabled: currentPage === 1 }">
          <button class="page-link" @click="prevPage">Previous</button>
        </li>
        <li
          class="page-item"
          :class="{ active: page === currentPage }"
          v-for="page in total_pages"
          :key="page"
        >
          <button class="page-link" @click="gotoPage(page)">{{ page }}</button>
        </li>
        <li
          class="page-item"
          :class="{ disabled: currentPage === total_pages }"
        >
          <button class="page-link" @click="nextPage">Next</button>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
import axios from "axios";
import DeviceForm from "./DeviceForm.vue";
import FileUpload from "./FileUpload.vue";
import QRCodeScanner from "./QRCodeScanner.vue";
import configService from "@/services/configService";
import CaptureImage from "./CaptureImage.vue";

export default {
  name: "DeviceList",
  components: {
    DeviceForm,
    FileUpload,
    QRCodeScanner,
    CaptureImage,
  },
  data() {
    return {
      devices: [],
      showForm: false,
      showFormList: false,
      showUploadForm: false,
      selectedDeviceId: null,
      currentPage: 1,
      total_pages: 1,
      showQRScanner: false,
      apiBaseUrl: "",
      showCaptureImage: false,
      isMobile: window.innerWidth <= 768,
    };
  },
  async created() {
    try {
      const config = await configService.loadConfig();
      this.apiBaseUrl = config.apiBaseUrl;
      this.fetchDevices();
    } catch (error) {
      console.error("Failed to load configuration:", error);
    }
    this.fetchDevices();
    window.addEventListener("resize", this.handleResize);
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    handleResize() {
      this.isMobile = window.innerWidth <= 768;
    },
    fetchDevices() {
      const token = localStorage.getItem("token");
      axios
        .get(
          `${this.apiBaseUrl}/devices?page=${this.currentPage}&per_page=10`,
          {
            headers: {
              "ngrok-skip-browser-warning": "true",
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          this.devices = response.data.devices;
          this.total_pages = response.data.total_pages;
        })
        .catch((error) => {
          console.error("Error fetching devices:", error);
          if (error.response && error.response.status === 401) {
            this.$router.push({ name: "Login" });
          }
        });
    },
    nextPage() {
      if (this.currentPage < this.total_pages) {
        this.currentPage++;
        this.fetchDevices();
      }
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
        this.fetchDevices();
      }
    },
    gotoPage(page) {
      this.currentPage = page;
      this.fetchDevices();
    },
    openForm(deviceId) {
      this.selectedDeviceId = deviceId;
      this.showForm = true;
    },
    showUploadList(deviceId) {
      this.$router.push(`/upload-list/${deviceId}`);
    },
    openUploadForm(deviceId) {
      this.selectedDeviceId = deviceId;
      this.showUploadForm = true;
    },
    openQRScanner() {
      this.showQRScanner = true;
    },
    closeQRScanner() {
      this.showQRScanner = false;
    },
    onQRCodeScanned(qrCode) {
      axios
        .get(`${this.apiBaseUrl}/?QRCode=${qrCode}`)
        .then((response) => {
          const device = response.data;
          this.$router.push({
            name: "DeviceDetail",
            params: { deviceId: device.ID },
          });
        })
        .catch((error) => {
          console.error("Error fetching device details:", error);
          if (error.response && error.response.status === 401) {
            this.$router.push({ name: "Login" });
          }
        })
        .finally(() => {
          this.showQRScanner = false;
        });
    },
    openCaptureImage(deviceId) {
      this.selectedDeviceId = deviceId;
      this.showCaptureImage = true;
    },
    navigateToCreateDevice() {
      this.$router.push({ name: "CreateDevice" });
    },
    editDevice(deviceId) {
      this.$router.push({ name: "EditDevice", params: { deviceId } });
    },
    viewDevice(deviceId) {
      this.$router.push({ name: "ViewDevice", params: { deviceId } });
    },
  },
};
</script>

<style scoped>
.container {
  margin-top: 20px;
}

.device-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 16px;
  margin-top: 20px;
}

.device-card {
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 16px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.card-body {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.card-title {
  margin-bottom: 8px;
}

.card-text {
  margin-bottom: 16px;
}

.btn-action {
  margin: 5px 0;
  width: 100%;
}
.btn-action-grid {
  margin: 10px; /* Add margin around each button */
  width: 140px; /* Adjust the width as needed */
}
.btn-action-top {
  margin: 10px;
  width: 200px;
}

@media (min-width: 768px) {
  .device-card {
    flex-direction: row;
    justify-content: space-between;
  }
}
</style>
