<template>
    <div class="container">
      <h1>Customer List</h1>
      <button @click="goToAddMerchant" class="btn btn-primary btn-action-top">Add Customer</button>
      <input
      type="text"
      v-model="searchQuery"
      @input="fetchMerchants"
      placeholder="Search by merchant name"
      class="form-control mb-3"
    />
      <table class="table">
        <thead>
          <tr>
            <th>Customer Code</th>
            <th>Customer Name</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="merchant in merchants" :key="merchant.id">
            <td>{{ merchant.merchantcode }}</td>
            <td>{{ merchant.merchantname }}</td>
            <td>
              <button @click="goToEditMerchant(merchant.id)" class="btn btn-secondary">Edit</button>
            </td>
          </tr>
        </tbody>
      </table>
        <!-- Pagination -->
        <nav aria-label="Page navigation">
        <ul class="pagination justify-content-center">
            <li class="page-item" :class="{ disabled: currentPage === 1 }">
            <button class="page-link" @click="prevPage">Previous</button>
            </li>
            <li
            class="page-item"
            v-for="page in totalPages"
            :key="page"
            :class="{ active: currentPage === page }"
            >
            <button class="page-link" @click="gotoPage(page)">{{ page }}</button>
            </li>
            <li class="page-item" :class="{ disabled: currentPage === totalPages }">
            <button class="page-link" @click="nextPage">Next</button>
            </li>
        </ul>
        </nav>    
        </div>
  </template>
  
  <script>
  import axios from 'axios';
  import configService from '@/services/configService';
  
  export default {
    name: 'MerchantList',
    data() {
      return {
        merchants: [],
        totalPages: 1,
        currentPage: 1,
        apiBaseUrl: ''
      };
    },
    async created() {
      try {
        const config = await configService.loadConfig();
        this.apiBaseUrl = config.apiBaseUrl;
        this.fetchMerchants();
      } catch (error) {
        console.error('Failed to load configuration:', error);
      }
    },
    methods: {
      async fetchMerchants() {
        const token = localStorage.getItem('token');
        try {
          const response = await axios.get(`${this.apiBaseUrl}/merchants`, {
            headers: {
              Authorization: `Bearer ${token}`
            },
            params: {
              merchantname: this.searchQuery,
              page: this.currentPage.value,
              per_page: 10
            }
          });
          this.merchants = response.data.merchants;
          this.totalPages = response.data.total_pages;
          this.currentPage = response.data.current_page;
        } catch (error) {
          console.error('Error fetching merchants:', error);
          if (error.response && error.response.status === 401) {
            this.$router.push({ name: 'Login' });
          }
        }
      },
    goToAddMerchant() {
        this.$router.push({ name: 'AddMerchant' });
      },
    goToEditMerchant(id) {
        this.$router.push({ name: 'EditMerchant', params: { id } });
      },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
        this.fetchMerchants();
      }
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
        this.fetchMerchants();
      }
    },
    gotoPage(page) {
      this.currentPage = page;
      this.fetchMerchants();
    },
    }
  };
  </script>
  
  <style scoped>
  .container {
    margin-top: 20px;
  }
  .btn-action-top {
  margin: 10px;
  width: 200px;
}
  </style>
  