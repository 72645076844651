<template>
  <div class="modal show d-block" tabindex="-1" role="dialog">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Manual Entry</h5>
          <button type="button" class="close" @click="$emit('close')">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <form @submit.prevent="submitForm">
            <div class="form-group">
              <label for="number01">Manual Value</label>
              <input
                type="number"
                class="form-control"
                id="number01"
                v-model="form.Number01"
                @input="validateNumber01"
                min="0"
              />
              <span v-if="number01Error" class="text-danger">{{
                number01Error
              }}</span>
            </div>
            <div class="form-group">
              <label for="text01">Model Output(optional)</label>
              <input
                type="text"
                class="form-control"
                id="text01"
                v-model="form.Text01"
              />
            </div>
            <div class="form-group">
              <label for="date">Date</label>
              <input
                type="date"
                class="form-control"
                id="date"
                v-model="form.Date"
                @input="validateDate"
              />
              <span v-if="dateError" class="text-danger">{{
                dateError
              }}</span>
            </div>
            <div class="form-group">
              <label for="time">Time</label>
              <input
                type="time"
                class="form-control"
                id="time"
                v-model="form.Time"
              />
            </div>
            <button type="submit" class="btn btn-primary">Submit</button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import configService from "@/services/configService";

export default {
  name: "DeviceForm",
  props: {
    deviceId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      form: {
        DeviceID: this.deviceId,
        Number01: "",
        Text01: "",
        Date: new Date().toISOString().split("T")[0], // Set default date to today
        Time: "",
      },
      apiBaseUrl: "",
      number01Error: "",
    };
  },
  async created() {
    try {
      const config = await configService.loadConfig();
      this.apiBaseUrl = config.apiBaseUrl;
      this.fetchDevices();
    } catch (error) {
      console.error("Failed to load configuration:", error);
    }
  },
  methods: {
    vvalidateNumber01() {
      if (this.form.Number01 < 0) {
        this.number01Error = "Number 01 cannot be less than 0";
        this.form.Number01 = 0;
      } else {
        this.number01Error = "";
      }
    },
    validateDate() {
      const today = new Date().toISOString().split("T")[0];
      if (this.form.Date > today) {
        this.dateError = "Date cannot be greater than today";
      } else {
        this.dateError = "";
      }
    },
    submitForm() {
      const token = localStorage.getItem("token");

      if (this.number01Error || this.dateError) {
        alert("Please correct the errors before submitting.");
        return;
      } else {
        axios
          .post(`${this.apiBaseUrl}/devicefileupload`, this.form, {
            headers: {
              "ngrok-skip-browser-warning": "true",
              Authorization: `Bearer ${token}`,
            },
          })
          .then((response) => {
            this.$emit("close");
            console.log("Form submitted successfully:", response.data);
          })
          .catch((error) => {
            console.error("Error submitting form:", error);
            if (error.response && error.response.status === 401) {
              this.$router.push({ name: "Login" });
            }
          });
      }
    },
  },
};
</script>

<style scoped>
.modal {
  display: block;
  background: rgba(0, 0, 0, 0.5);
}
.text-danger {
  color: red;
}
</style>
