<template>
  <div class="container">
    <h2>Login</h2>
    <form @submit.prevent="login">
      <div class="form-group">
        <label for="email">Email:</label>
        <input type="email" v-model="email" class="form-control" required />
      </div>
      <div class="form-group">
        <label for="password">Password:</label>
        <input
          type="password"
          v-model="password"
          class="form-control"
          required
        />
      </div>
      <button type="submit" class="btn btn-primary">Login</button>
    </form>
  </div>
</template>

<script>
import axios from "axios";
import configService from "@/services/configService";

export default {
  data() {
    return {
      email: "",
      password: "",
      apiBaseUrl: "",
    };
  },
  async created() {
    try {
      const config = await configService.loadConfig();
      this.apiBaseUrl = config.apiBaseUrl;
    } catch (error) {
      console.error("Failed to load configuration:", error);
    }
  },
  methods: {
    login() {
      axios
        .post(`${this.apiBaseUrl}/login`, {
          useremail: this.email,
          password: this.password,
        })
        .then((response) => {
          localStorage.setItem("token", response.data.token);
          localStorage.setItem('is_admin', response.data.is_admin);
          this.$router.push({ name: "DeviceList" });
        })
        .catch((error) => {
          console.error("Login failed:", error);
          alert("Login failed. Please check your credentials.");
        });
    },
  },
};
</script>
