<template>
  <div
    class="modal show d-block"
    role="dialog"
    id="resetPasswordModal"
    tabindex="-1"
    aria-labelledby="resetPasswordModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="resetPasswordModalLabel">
            Reset Password
          </h5>
          <button type="button" class="close" @click="$emit('close')">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <form @submit.prevent="resetPassword">
            <div class="mb-3">
              <label for="newPassword" class="form-label">New Password</label>
              <input
                type="password"
                v-model="newPassword"
                class="form-control"
                id="newPassword"
                required
              />
            </div>
            <button type="submit" class="btn btn-primary">
              Reset Password
            </button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import configService from "@/services/configService";

export default {
  props: {
    userId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      newPassword: "",
      apiBaseUrl: "",
    };
  },
  async created() {
    try {
      const config = await configService.loadConfig();
      this.apiBaseUrl = config.apiBaseUrl;
    } catch (error) {
      console.error("Failed to load configuration:", error);
    }
  },
  methods: {
    async resetPassword() {
      try {
        const token = localStorage.getItem("token");
        await axios.post(
          `${this.apiBaseUrl}/reset-password`,
          {
            userId: this.userId,
            newPassword: this.newPassword,
          },
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );

        alert("Password reset successfully.");
        this.newPassword = "";
        this.$emit("passwordReset");
        this.$emit("close");
      } catch (error) {
        console.error("Error resetting password:", error);
        alert("Failed to reset password.");
      }
    },
  },
};
</script>
