<template>
  <div class="container">
    <h1 class="my-4">Past Uploads for Device {{ deviceId }}</h1>
    <table class="table table-bordered">
      <thead>
        <tr>
          <th>Created On</th>
          <th>Number 01</th>
          <th>Text 01</th>
        </tr>
      </thead>
    </table>
  </div>
</template>

<script>
export default {
  name: "UploadListCheck",
  props: ["deviceId"],
  data() {
    return {
      uploads: [],
    };
  },
  created() {},
  methods: {},
};
</script>

<style scoped>
/* Your component styles */
</style>
